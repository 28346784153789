@font-face {
  font-family: 'Arbutus Slab';
  src: url('/src/assets/fonts/ArbutusSlab-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/ProximaNova-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/ProximaNova-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/ProximaNova-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/ProximaNova-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/ProximaNova-Extrabold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/ProximaNova-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/ProximaNova-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/ProximaNova-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/ProximaNova-SemiboldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/ProximaNova-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}
