#printErrorMessage {
  display: none;
}

@media print {
  #printContainer,
  #root {
    display: none !important;
  }
  #printErrorMessage {
    display: block;
  }
}
